import styled, { css } from "styled-components";
import { device } from "utils/device";

interface ModalProps {
  isModal: boolean;
}

export const CardContainer = styled.div<ModalProps>`
  display: flex;
  flex-direction: column;
  row-gap: 24px;
  flex: 0 1 calc((100% - 24px) / 2);
  padding: 24px;
  box-shadow: 0px 12px 56px 0px rgba(109, 162, 223, 0.16);
  border-radius: 16px;

  @media ${device.tablet} {
    flex: none;
    width: 100%;

    ${({ isModal }) => css`
      height: ${isModal ? "100%" : "unset"};
      box-shadow: ${isModal ? "none" : "0px 12px 56px 0px rgba(109, 162, 223, 0.16)"};
      border-radius: ${isModal ? "0" : "16px"};
    `}
  }

  ${(p) => css`
    background-color: ${p.theme.colors.bg.main};
    border: 1px solid ${p.theme.colors.border.default};
  `}
`;

export const CardInfoBlock = styled.div<ModalProps>`
  display: flex;
  flex-direction: column;
  row-gap: 16px;

  @media ${device.tablet} {
    overflow: ${(p) => (p.isModal ? "auto" : "auto")};
  }
`;

export const CardHeading = styled.h3`
  font-size: 24px;
  font-weight: 800;
  line-height: 32px;
`;

export const CardDate = styled.time`
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  color: ${(p) => p.theme.colors.textSecondary};
`;

export const Location = styled.div`
  display: flex;
  align-items: center;
  column-gap: 8px;

  .location-icon path {
    stroke: ${(p) => p.theme.colors.blue};
  }
`;

export const Address = styled.span`
  display: inline-block;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
`;

export const Description = styled.div<ModalProps>`
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  line-clamp: 3;

  ${({ isModal }) => css`
    display: ${isModal ? "unset" : "-webkit-box"};
  `}
`;

export const ReqBlock = styled.div``;

export const ReqHeading = styled.h4`
  margin-bottom: 8px;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  text-transform: uppercase;
  color: ${(p) => p.theme.colors.textSecondary};
`;

export const ReqList = styled.ul`
  display: flex;
  flex-direction: column;
  row-gap: 8px;
  font-size: 14px;
`;

export const ReqListItem = styled.li`
  position: relative;
  padding-left: 12px;
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;

  ::before {
    content: "";
    display: block;
    position: absolute;
    top: 12px;
    left: 0;
    transform: translateY(-50%);
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background-color: ${(p) => p.theme.colors.blue};
  }
`;

export const ActionButtonContainer = styled.div<ModalProps>`
  width: 100%;
  @media ${device.tablet} {
    margin-top: ${(p) => (p.isModal ? "auto" : "unset")};
  }
`;
